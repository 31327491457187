import { PublicationContextProvider } from "common/context/Publication";
import Core from "Containers/Core";
import type {
  GetServerSideProps,
  InferGetServerSidePropsType,
  NextPage,
} from "next";
import {
  fetchJobFeed,
  fetchLayoutData,
  fetchSectionData,
  renderPublication,
} from "../../../../lib";
import { setHeaders } from "../../../common/Utils/headers";

const SecondaryRoute: NextPage = ({
  data,
  menus,
  jobs,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return <Core jobs={jobs} menus={menus} data={data} />;
};

export default SecondaryRoute;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { res, req } = context;
  setHeaders(req, res);
  try {
    const publication = renderPublication(context.req);
    const data: any = await fetchSectionData(
      publication,
      `?path=${context.params?.primary_route}/${context.params?.seconday_route}`
    );
    const props = {
      data,
      publication,
      menus: await fetchLayoutData(publication),
      jobs: await fetchJobFeed(publication),
    };

    if (data.data.type === "redirect") {
      return {
        redirect: {
          destination: data.data.destination,
          permanent: true,
        },
      };
    }
    if (props.data.data.type === "NOT_FOUND") {
      return { notFound: true };
    }
    context.res.setHeader(
      "Cache-Tag",
      `tindle_${process.env.NEXT_PUBLIC_ENV_STATE}, ${data.data.cacheKeys}`
    );
    return {
      props,
    };
  } catch (err) {
    throw Error;
  }
};
