import { FC, useRef, useEffect, useContext } from "react";
import Redirect from "Components/Redirect";
import dynamic from "next/dynamic";
import { LayoutProps } from "common/Layout/types/Layout.types";
import { useRouter } from "next/router";
import Service from "common/Layout/Article/Service";
import ConnectedSection from "Containers/ConnectedSection";
import ConnectedArticle from "Containers/ConnectedArticle";
import { DataLayerContext } from "common/context/DataLayer";

const Section = dynamic(() => import("common/Layout/Section"));
const Article = dynamic(() => import("common/Layout/Article"));

type Props = {
  data: any;
  menus: LayoutProps;
  jobs: any;
};

const Core: FC<Props> = ({ data, menus, jobs }) => {
  const { setDataLayer } = useContext(DataLayerContext);

  useEffect(() => {
    if (!data?.data) {
      return;
    }

    const { type, sections, section, authors, topics, publish } = data.data;

    setDataLayer?.(() => {
      return {
        page_type: type === "article" ? "article" : "non-article",
        section:
          section?.name ||
          (sections && sections.length > 0 ? sections[0].name : undefined),
        author: Array.isArray(authors)
          ? authors.map(({ name }: { name: string }) => name).join(", ")
          : undefined,
        keywords: Array.isArray(topics)
          ? topics.map(({ name }: { name: string }) => name).join(", ")
          : undefined,
        publish_date: publish
          ? new Date(publish * 1000).toISOString()
          : undefined,
        paywall: false,
      };
    });
  }, [data]);

  if (!data) {
    return <Redirect destination={"/"} />;
  }

  const service =
    data.data?.articleTypes && data.data?.articleTypes[0]?.name === "Service";
  if (service) {
    return <Service article={data} menus={menus} />;
  }
  if (data?.data?.type === "redirect") {
    return <Redirect destination={data?.data?.destination} />;
  }
  if (data?.data?.type === "topic") {
    return <ConnectedSection jobs={jobs} menus={menus} data={data} />;
  }
  if (data?.data?.type === "section") {
    return <ConnectedSection jobs={jobs} menus={menus} data={data} />;
  }
  if (data?.data?.type === "author") {
    return <ConnectedSection jobs={jobs} menus={menus} data={data} />;
  }
  if (data?.data?.type === "article") {
    return <ConnectedArticle menus={menus} data={data} />;
  }
  if (data?.data?.type === "topic") {
    return <ConnectedSection jobs={jobs} menus={menus} data={data} />;
  }

  return <Redirect destination={"/"} />;
};

export default Core;
