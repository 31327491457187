import { FC } from "react";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { AdvertContextProvider } from "common/context/Adverts";

const Section = dynamic(() => import("common/Layout/Section"));
type Props = {
  data: any;
  menus: any;
  jobs: any;
};

const ConnectedSection: FC<Props> = ({ data, menus, jobs }) => {
  const { push } = useRouter();
  useEffect(() => {
    if (data?.data?.type === "redirect") {
      push(data.data.destination);
    }
    if (data?.data?.type === "NOT_FOUND") {
      push("/");
    }
    if (data === "err") {
      push("/");
    }
    if (!data) {
      push("/");
    }
  }, []);

  if (data?.data.type === "section") {
    return (
      <AdvertContextProvider config={data.addConfig}>
        <Section jobs={jobs} menus={menus} data={data} />
      </AdvertContextProvider>
    );
  }
  if (data?.data?.type === "topic") {
    return <Section jobs={jobs} menus={menus} data={data} />;
  }

  if (data?.data.type === "author") {
    return <Section jobs={jobs} menus={menus} data={data} />;
  }
  return null;
};

export default ConnectedSection;
