import { FC } from "react";
import dynamic from "next/dynamic";
import { AdvertContextProvider } from "common/context/Adverts";
const Article = dynamic(() => import("common/Layout/Article"));

export const config = { amp: true };

type Props = {
  data: any;
  menus: any;
};

const ConnectedArticle: FC<Props> = ({ data, menus }) => {
  if (data?.data?.type === "article") {
    const disableAllAds = data.data.extra.isDisableAllAds;
    return (
      <AdvertContextProvider
        disableAllAds={disableAllAds}
        config={data.addConfig}
      >
        <Article menus={menus} article={data} />
      </AdvertContextProvider>
    );
  }
  return null;
};
export default ConnectedArticle;
